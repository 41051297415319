<template>
    <div>
    <div v-if="userData.role === 'PLSYR' && !$store.state.plasiyerCustomer">
      <customer-list v-if="userData.role === 'PLSYR' && $vuetify.breakpoint.xsOnly" />
      <v-alert
        v-if="!$store.state.plasiyerCustomer"
        text
        outlined
        class="mx-3 mx-lg-0 rounded-pill mt-2 mt-md-0 text-caption"
        type="warning"
      >
        Lütfen yukarıdan bir müşteri seçin
      </v-alert>
    </div>
    <v-navigation-drawer
      v-if="isDrawerMode"
      :value="isAddNewActionsSidebarActive"
      temporary
      touchless
      floating
      stateless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 500 : '100%'"
      app
      @input="val => $emit('update:is-add-new-actions-sidebar-active', val)"
    >

      <v-sheet :color="isDark ? '#363351' : '#fafafa'" class="pa-3 d-flex align-center justify-space-between">
        <div class="d-flex align-center">
          <v-avatar size="48" color="secondary">
            <v-icon size="24" class="white--text">{{ formIcon }}</v-icon>
          </v-avatar>
          <div class="text-h6 font-weight-medium ml-3">{{ formTitle }}</div>
        </div>
        <v-btn icon x-small @click="$emit('update:is-add-new-actions-sidebar-active', false)">
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-sheet>
  
      <form @submit.prevent="submit">
        <validation-observer ref="observer">
            <validation-provider v-slot="{ errors }" name="Aksiyon Tipi" rules="required">
                <v-select
                  v-model="messageData.ACTIONTYPE"
                  :items="['GENEL', 'TIP 2', 'TIP 3']"  
                  
                  label="Aksiyon Tipi"
                  :error-messages="errors"
                  hide-details
                  class="rounded-0 input-field"
                ></v-select>
              </validation-provider>
          
              <!-- Açıklama -->
              <validation-provider v-slot="{ errors }" name="Başlık" rules="required|max:255">
                <v-textarea
                  v-model="messageData.content"                  
                  label="Başlık"
                  :error-messages="errors"
                  hide-details
                  class="rounded-0 input-field"
                ></v-textarea>
              </validation-provider>
          
              <!-- Genel Açıklama -->
              <validation-provider v-slot="{ errors }" name="Genel Açıklama" rules="required|max:500">
                <v-textarea
                  v-model="messageData.generalContent"
                  
                  label="Genel Açıklama"
                  :error-messages="errors"
                  hide-details
                  class="rounded-0 input-field"
                ></v-textarea>
              </validation-provider>
          
              <!-- Aksiyon Tarihi -->
              <validation-provider v-slot="{ errors }" name="Aksiyon Tarihi" rules="required">
                <v-menu
                  v-model="datePicker"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"   
                >
                  <template v-slot:activator="{ on,attrs }">
                    <v-text-field
                      v-model="messageData.actionDate"
                      label=" Aksiyon Tarihi"
                      :prepend-icon="icons.mdiCalendar"                       
                      v-bind="attrs"
                      v-on="on"                   
                      :error-messages="errors"    
                      class="rounded-0 input-field"      
                    ></v-text-field>
                  </template>
                  <v-date-picker 
                    v-model="messageData.actionDate" 
                        no-title
                        color="primary"
                        @input="datePicker = false">
                 </v-date-picker>
                </v-menu>

              </validation-provider>
          
            </validation-observer>
          
            <div class="pa-3">
              <v-btn x-large rounded block :loading="loading" color="secondary" @click="submit">
                {{ formButtonText }}
              </v-btn>
            </div>

      </form>
    </v-navigation-drawer>  
    <div v-else>
        <v-row no-gutters>
            <v-col>    
              <customer-list v-if="(userData.role === 'PLSYR' || userData.role === 'FIN' ) && $vuetify.breakpoint.xsOnly" />
                    
              <v-card tile :flat="$vuetify.breakpoint.mdAndDown" :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg'">
                <v-toolbar  :color="isDark ? '#312d4b' : '#fafafa'"   :flat="!$vuetify.breakpoint.smAndDown" :height="$vuetify.breakpoint.smAndDown ? 57 : 87">
                  <v-toolbar-title  class="text-body-2 text-md-h6 font-weight-medium">
                    <v-icon :large="!$vuetify.breakpoint.smAndDown" left>
                        {{ icons.mdiFormatListGroupPlus }}
                    </v-icon>
                    {{ formTitle }}
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-divider></v-divider>
            
          
                <v-form style="padding: 20px" ref="observer" @submit.prevent="sendFields" class="pa-4">
                  <v-row align="center"  class="mb-0">
                    <v-col cols="12" md="3" class="pa-0 d-flex align-center">
                      <v-subheader>Aksiyon Tipi</v-subheader>          
                    </v-col>         
                    <v-col cols="12" md="3" class="pa-0">
                      <v-autocomplete
                        v-model="ACTIONTYPE"
                        :items="['GENEL', 'TIP 2', 'TIP 3']"  
                        item-text="STEXT"
                        item-value="LEAVECODE"
                        label="Aksiyon Tipi Seçin"
                        outlined
                        dense
                        hide-details
                        :rules="[v => !!v || 'Seçiniz']"                      
                        return-object
                        required
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                   
                  <v-row align="center"  class="mb-0">
                    <v-col cols="12" md="3" class="pa-0 d-flex align-center">
                      <v-subheader>Başlık</v-subheader> 
                    </v-col>
                    <v-col cols="12" md="6" class="pa-0">
                      <v-textarea v-model="content" rows="4" outlined dense hide-details
                      class="mt-1"
                      :rules="[
                        v => !!v || 'Bu alan zorunludur.',
                        v => v.length <= 255 || 'Maksimum 255 karakter girilebilir.'
                      ]"
                      ></v-textarea>
                    </v-col>
                  </v-row>

                  <v-row align="center"   class="mb-0">
                    <v-col cols="12" md="3" class="pa-0 d-flex align-center">
                      <v-subheader>Genel Açıklama</v-subheader> 
                    </v-col>
                    <v-col cols="12" md="6" class="pa-0">
                      <v-textarea v-model="generalContent"      
                      rows="4" 
                      outlined
                      dense
                      hide-details
                      class="mt-1"              
                      :rules="[
                        v => !!v || 'Bu alan zorunludur.',
                        v => v.length <= 500 || 'Maksimum 500 karakter girilebilir.']"></v-textarea>
                    </v-col>
                  </v-row>

                  <v-row align="center"  class="mb-0">
                    <v-col cols="12" md="3" class="pa-0 d-flex align-center">
                      <v-subheader>Aksiyon Tarihi</v-subheader> 
                    </v-col>
                    <v-col cols="12" md="3" class="pa-0">
                      <v-menu 
                        v-model="datePicker" 
                        attach="body"
                        content-class="custom-menu"
                        :close-on-content-click="true" 
                        transition="scale-transition" offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field                           
                            v-model="actionDate"                  
                            persistent-hint 
                            :prepend-icon="icons.mdiCalendar" 
                            v-bind="attrs" 
                            v-on="on" 
                            outlined 
                            dense 
                            hide-details
                            required
                            :rules="[
                                    v => !!v || 'Bu alan zorunludur.'
                                ]"
                          ></v-text-field>
                        </template>
                        <v-date-picker 
                          v-model="actionDate" 
                          no-title 
                          color="primary" 
                          @input="datePicker = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
          
                  <v-row align="center"  class="mb-0">
                    <v-col offset-md="3" cols="6" class="pa-0">
                      <v-btn :loading="loading" @click="submit" color="success" depressed class="white--text">
                        <span>Kaydet</span>                       
                        <v-icon dark right>
                          {{ icons.mdiCheckboxMarkedCircleOutline }}
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
            </v-col>
        </v-row> 
    </div>


</div>
  </template>
  
  <script>
  import Vue from 'vue';
  import { mdiClose, mdiPlusThick,mdiFormatListChecks,mdiFormatListGroupPlus } from '@mdi/js';
  import useAppConfig from '@core/@app-config/useAppConfig';
  import { ref} from '@vue/composition-api';
  import CustomerList from '@/components/CustomerList.vue'

  export default {
    components: {     
      CustomerList,
    },
    name: 'ActionsListAddNew',

    model: {
      prop: 'isAddNewActionsSidebarActive',
      event: 'update:is-add-new-actions-sidebar-active',
    },
    props: {
      isAddNewActionsSidebarActive: {
        type: Boolean,
        required: false,
      },
      editMessageData: {
        type: Object,
        required: false,
        default: () => ({}),
      },
      isDrawerMode: {
        type: Boolean,
        required: false,
      }
    },
    setup(props) {
      const userData = JSON.parse(localStorage.getItem('userData'))
      const { isDark } = useAppConfig();    
      const ACTIONTYPE = ref('');
      const content = ref('');
      const generalContent = ref('');
      
      const actionDate = ref(new Date().toISOString().substr(0, 10)); // Bugünün tarihini YYYY-MM-DD formatında
      //const start_date = ref(moment().format('YYYY-MM-DD'));
      return {
        isDark,  
        userData  ,
        ACTIONTYPE   ,
        content     ,
        generalContent,
        actionDate
      };
    },
    data() {
      return {        
        loading: false,      
        messageData: {
          id: this.editMessageData ? this.editMessageData.id : null,
          stext: this.editMessageData ? this.editMessageData.stext : '',
          content: this.editMessageData ? this.editMessageData.content : '',       
          is_active: this.editMessageData ? Number(this.editMessageData.is_active) === 1 : false,
          
        },
        datePicker: false,
        icons: {
          mdiClose,
          mdiPlusThick,
          mdiFormatListChecks,
          mdiFormatListGroupPlus
        },
      };
    },
    computed: {
      formIcon() {
        return this.icons.mdiFormatListChecks;
      },
      formTitle() {
        return this.editMessageData && Object.keys(this.editMessageData).length > 0
          ? 'Aksiyon Düzenle'
          : 'Yeni Aksiyon Ekle';
      },
      formButtonText() {
        return this.editMessageData && Object.keys(this.editMessageData).length > 0
          ? 'Güncelle'
          : 'Kaydet';
      },
    },
    watch: {
        isAddNewActionsSidebarActive(val) {
        console.log('is_Active',this.editMessageData.is_active); 

        if (val && this.editMessageData && Object.keys(this.editMessageData).length > 0) {
          this.$nextTick(() => {
            this.messageData = {
              id: this.editMessageData.id,
              stext: this.editMessageData.stext,
              content: this.editMessageData.content,
             is_active: this.editMessageData ? Number(this.editMessageData.is_active) === 1 : false,
            };
          });
          console.log('is_Active2',this.messageData.is_active); 

        }
        if (!val) {
          this.close();
        }
      },
    },
    methods: {
      async submit() {
        debugger
        const isValid = await this.$refs.observer.validate();
        if (!isValid) {
          Vue.swal({
            title: 'Hata',
            html: 'Lütfen zorunlu alanları doldurunuz',
            icon: 'error',
            background: '#FF4C51',
            timerProgressBar: true,
            showConfirmButton: true,
          });
        } else {
            const result = await Vue.swal({
                title: 'Onay',
                text: 'Kaydetmek istediğinize emin misiniz?',
                icon: 'question',
                buttons: true,
                dangerMode: true,
                });

            if (result === true) {
            try {
                debugger         
                    const response = await this.$store.dispatch('postMethod', {
                    method: 'saveOpenerMsg',
                    id: this.editMessageData && this.editMessageData.id ? this.editMessageData.id : null,
                    'fieldx[tr][stext]': this.messageData.stext,
                    'fieldx[tr][content]': this.messageData.content,
                    'field[is_active]': this.messageData.is_active ? 1 : 0,
                    
                    'field[create_datetime]': this.formatDate(new Date()),
                    });
        
                    if (response.error === 1) {
                    Vue.swal({
                        title: 'Hata',
                        html: response.msg,
                        icon: 'error',
                        background: '#FF4C51',
                        timerProgressBar: true,
                        showConfirmButton: false,
                    });
                    } else {
                    this.$emit('refetch-data')
                    this.$emit('update:is-add-update-sidebar-active', false)
        
                    
                    this.$emit('save-product-event', {
                        message: `Veri Girişi Başarılı`,
                        color: 'success',               
                        timeout: 2000,
                        timerProgressBar: true,
                        showConfirmButton: false,
                    })
                    this.loading = false             
                    }            
                } catch (error) {
                    console.error('Error during saving:', error.message || error);
                    Vue.swal({
                    title: 'Hata',
                    html: 'Bir hata oluştu, lütfen tekrar deneyin.',
                    icon: 'error',
                    background: '#FF4C51',
                    timerProgressBar: true,
                    showConfirmButton: true,
                    });
                }
            }
            else {
            // Kullanıcı onayı reddettiğinde yapılacak işlemler
            Vue.swal({
                title: 'İptal Edildi',
                text: 'Kaydetme işlemi iptal edildi.',
                icon: 'info',                
                timerProgressBar: true,
                showConfirmButton: false,
                timer: 2000
            });
            }
        }
      },
      formatDate(date) {
        const year = date.getFullYear();
        const day = String(date.getDate()).padStart(2, '0'); // Gün, 2 basamaklı olacak
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Ay, 0'dan başlar, 1 ekle
        return `${year}-${month}-${day}`; // yyyy-dd-mm formatı
        },
        close() {
          this.$refs.observer.reset();
          this.$nextTick(() => {
            this.messageData = {
              id: '',
              stext: '',
              content: '',
              is_active: '',
            };
          });
        },
    },
  };
  </script>
  
  <style scoped>
  .input-field {
    margin-left: 20px; /* Sol boşluk */
    margin-bottom: 16px; /* Alt boşluk */
  }

  .custom-menu {
    z-index: 2000 !important;
    position: absolute !important;
    left: 50% !important; /* Menü soldan %50 uzaklıkta başlasın */
    transform: translateX(-50%); /* Ortalar */
  }
  </style>
  